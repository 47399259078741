import React from "react";
import { DisplaySettings, UserConsent } from "../../../../IsaacAppTypes";
import { SITE_TITLE, isAda, siteSpecific } from "../../../services";
import { StyledCheckbox } from "../inputs/StyledCheckbox";
import { MyAccountTab } from "./MyAccountTab";
import { Link } from "react-router-dom";

interface RevisionModeInputProps {
    displaySettings: DisplaySettings;
    setDisplaySettings: (ds: DisplaySettings | ((oldDs?: DisplaySettings) => DisplaySettings)) => void;
}
interface UserBetaFeaturesProps extends RevisionModeInputProps{
    consentSettings: UserConsent;
    setConsentSettings: (cs: UserConsent | ((oldUC?: UserConsent) => UserConsent)) => void;
}

export const RevisionModeInput = ({displaySettings, setDisplaySettings}: RevisionModeInputProps) => {
    return <StyledCheckbox checked={displaySettings.HIDE_QUESTION_ATTEMPTS ?? false}
        onChange={e => {
            setDisplaySettings((oldDs) => ({...oldDs, HIDE_QUESTION_ATTEMPTS: e.target.checked}));
        }}
        color={siteSpecific("primary", "")}
        label={<p>Hide previous question attempts</p>}
        id={"hide-previous-q-attempts"}
    />;
};

export const UserBetaFeatures = ({ displaySettings, setDisplaySettings, consentSettings, setConsentSettings }: UserBetaFeaturesProps) => {
    return <MyAccountTab
        leftColumn={<>
            <h3>Beta Features</h3>
            <p>Here you can opt-in to beta features of the {SITE_TITLE} platform.</p>
        </>}
        rightColumn={<>
            <>
                <b><RevisionModeInput {...{displaySettings, setDisplaySettings}}/></b>
                <p>{`This feature lets you answer questions ${siteSpecific("that you have answered before, without seeing your old answer.", "again, even if you've answered them before.")} It's useful if you are reviewing a topic before a test or exam.`}</p>
            </>
            {isAda && <>
                <StyledCheckbox checked={consentSettings.OPENAI ?? false}
                    onChange={e => {
                        setConsentSettings((oldCS) => ({...oldCS, OPENAI: e.target.checked}));
                    }}
                    label={<p><b>Consent to sending free-text answers to OpenAI for marking</b></p>}
                    id={"consent-to-openai-marking"}
                />
                <p>
                    We use a large language model (LLM) to mark free-text questions on the site.
                    We only send your answer to OpenAI, we do not send any personal data.
                    For more information, read our <Link to="/support/student/general" target="_blank">FAQs</Link>.
                </p>
            </>}
            <div className="section-divider"/>
            <>
                <b><StyledCheckbox checked={displaySettings.REDUCED_MOTION ?? false}
                    onChange={e => {
                        setDisplaySettings((oldDs) => ({...oldDs, REDUCED_MOTION: e.target.checked}));
                    }}
                    color={siteSpecific("primary", "")}
                    label={<p>Prefer reduced motion</p>}
                    id={"reduced-motion"}
                /></b>
                <p>{`Enabling this will reduce motion effects on the platform. Browser preference will take priority over this setting.`}</p>
            </>
            <>
                <b><StyledCheckbox checked={displaySettings.PREFER_MATHML ?? false}
                    onChange={e => {
                        setDisplaySettings((oldDs) => ({...oldDs, PREFER_MATHML: e.target.checked}));
                    }}
                    color={siteSpecific("primary", "")}
                    label={<p>Use MathML for accessible maths</p>}
                    id={"prefer-mathml"}
                /></b>
                <p>{`With this setting you can toggle between using alternative text or MathML for mathematical equations.`}</p>
            </>
        </>}
    />;
};
